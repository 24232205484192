.content {
    @include margin-padding(null, 40px 0);
    .content-header {
        padding-bottom: 40px;
        @include respond-below(custom767) {
            padding-bottom: 20px;
        }
        h4 {
            font-size: $__font__size__32;
            font-weight: $__bold;
            color: $__black;
            margin-bottom: 0;
            @include respond-below(custom991) {
                font-size: $__font__size__28;
            }
            @include respond-below(custom767) {
                font-size: $__font__size__26;
            }
        }
    }
    .content-settings-header {
        padding-bottom: 15px;
    }
    &.dashboard-content {
        padding-bottom: 16px;
    }
}