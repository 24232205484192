
.react-calendar {
  max-width: 100%;
  background-color: #fff;
  color: #222;
  border-radius:6px;
  line-height: 1.125em;
  border: none;
}
 
.react-calendar__navigation button {
  color: #2e3840;
  min-width: 44px;
  background: none;
  font-size: 16px;
  margin-top: 8px;
}
 
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #f8f8fa;
}
.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}
abbr[title] {
  text-decoration: none;
}
 
/* .react-calendar__month-view__days__day--weekend {
  color: #d10000;
 } */
 
/* .react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background: #f8f8fa;
  border-radius: 10px;
  color: #2e3840;
} */
 
.react-calendar__tile--now {
  background: #2e384033;
  color: #2e3840;
}
 
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #2e384033;
  border-radius:6px;
  color: #2e3840;
}
 
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #f8f8fa;
}
 
/* .react-calendar__tile--active {
  background: #2e3840;
  border-radius:6px;
  color: white;
} */
 
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #2e3840;
  color: white;
}
 
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #f8f8fa;
}
 
/* .react-calendar__tile--range {
  background: #f8f8fa;
  color: #2e3840;
  border-radius:6px;
} */
 
/* .react-calendar__tile--rangeStart {
  border-radius:6px;
  background: #2e3840;
  color: white;
} */
/*  
.react-calendar__tile--rangeEnd {
  border-radius:6px;
  background: #2e3840;
  color: white;
} */



.disabled-date, .disabled-date:hover, .disabled-date:focus {
  background-color: #f0f0f0 !important;
  color: #ababab !important;
  cursor: not-allowed !important;
}

.active-date {
  background-color: #dd000b !important;
  border-radius: 10px;
  color: #f0f0f0 !important;
}

.active-date-start {
  background-color: #dd000b !important;
  color: #f0f0f0 !important;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.active-date-middle {
  background-color: #dd000b !important;
  color: #f0f0f0 !important;
  border-radius: 0px !important;
}

.active-date-end {
  background-color: #dd000b !important;
  color: #f0f0f0 !important;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}


.highlight-date {
  /* background: #2e3840; */
  color: white;
  border-radius:6px;
}

.highlight-date-start {
  /* background: #2e3840; */
  color: white;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.highlight-date-middle {
  /* background: #2e3840; */
  color: white;
  border-radius: 0px !important;
}

.highlight-date-end {
  /* background: #2e3840; */
  color: white;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.calendar-container {
  position: relative;
  display: inline-block;
  z-index: 100;

}

.hover-target {
  padding: 10px;
  background: #fcfbfb;
  border: 1px solid #f4f4f4;
  border-radius: 5px;
  box-shadow: none;
  color: #201f1d;
  cursor: pointer !important;
  font-size: 14px;
  font-weight: 400;
  margin: 0;
  padding-right: 0;
  cursor: pointer;

}

.calendar-popup {
  position: absolute;
  top: -280px;
  left: 0;
  z-index: 100;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  background-color: white;
  padding: 10px;
  border: 1px solid #ccc;
  z-index: 100;
}

.react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus, .react-calendar__tile--active {
  background-color: #dd000b;
  color: white;
}

@media (max-width: 768px) {
  .react-calendar__tile:enabled:hover {
    background-color: inherit;
    color: inherit;
  }
  .react-calendar__tile--active {
    background-color: #dd000b !important;
    color: white !important;
  }
}


.react-calendar__month-view__days {
  background: #f0f0f0;
}


.calendar-element-sold {
  color: transparent;
}

.calendar-element-sold:after {
  content: 'Sold';
  font-weight: bold;
  color: #dd000b !important;
  margin-left: -10px;
}

.calendar-element-sold:hover {
  cursor: not-allowed !important;
  color: transparent !important;
  background: #f0f0f0 !important;

}