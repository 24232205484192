@import "primereact/resources/themes/lara-light-indigo/theme.css";
@import "react-time-picker/dist/TimePicker.css";
@import "react-clock/dist/Clock.css";
@import "primeicons/primeicons.css";
@import "primereact/resources/primereact.css";
@import "primereact/resources/themes/lara-light-indigo/theme.css";


@font-face {
  font-family: "BDRmono2021RoundedBold";
  src: url('style/webFonts/BDRmono2021RoundedBold/font.woff2') format('woff2'), url('style/webFonts/BDRmono2021RoundedBold/font.woff') format('woff');
}

.primaryFont { 
	font-family: BDRmono2021RoundedBold !important;
  text-transform: uppercase;
}


.stickybar {
  top: 100px;
  position: sticky;
}
.p-calendar.p-component.p-inputwrapper .p-inputtext.p-component {
  background: #fcfbfb;
  border: 1px solid #f4f4f4;
  box-shadow: none;
  border-radius: 5px;
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  color: #201f1d;
  padding-right: 0;
  cursor: pointer !important;
}
.p-calendar.p-component.p-inputwrapper input:focus {
  box-shadow: none;
}
.search-box-banner .group-img i {
  top: 23px;
}
.p-calendar.p-component.p-inputwrapper input::placeholder {
  font-size: 14px;
}
.p-datepicker-calendar-container table.p-datepicker-calendar {
  font-size: 14px;
}
div.p-datepicker table td.p-datepicker-today > span {
  background: #dd000b;
  color: #fff;
}
.p-calendar.p-component input {
  font-size: 14px;
}
.group-img div.ant-picker {
  height: 45px;
  padding: 0 10px 0 0;
}
.group-img .ant-picker .ant-picker-input input {
  text-overflow: unset;
  padding: 2px 13px 13px 36px;
}
.group-img .ant-picker .ant-picker-input {
  top: 10px;
}
.group-img .ant-picker .ant-picker-input input::placeholder {
  color: #787878;
}
.ant-picker-input span.ant-picker-suffix {
  display: none;
}
/* ant datatable design */
.ant-pagination .ant-pagination-item-active {
  border-color: #127384;
  border-radius: 10px;
  color: #ffffff;
  background-color: #127384;
  width: 40px;
  height: 35px;
}
/* Action filed style in user walletpage */
/* .dropdown-action .dropdown-toggle {
  width: 30px;
  height: 30px;
  font-size: 16px;
  color: #737373;
  border-radius: 5px;
  border: 1px solid #dbdbdb;
  background: #ffffff;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
} */
/* Antd table header  */
.ant-table-wrapper .ant-table-thead > tr > th,
:where(.css-dev-only-do-not-override-1rqnfsa).ant-table-wrapper
  .ant-table-thead
  > tr
  > td {
  color: #111;
  background: #f2f7f6 !important;
}
:where(.css-dev-only-do-not-override-1rqnfsa).ant-table-wrapper
  .ant-table-thead
  > tr
  > th,
:where(.css-dev-only-do-not-override-1rqnfsa).ant-table-wrapper
  .ant-table-thead
  > tr
  > td {
  position: relative;
  color: rgba(0, 0, 0, 0.88);
  font-weight: 700 !important;
  text-align: start;
  background: #fafafa;
  border-bottom: 1px solid #f0f0f0;
  font-family: "Fira Sans", sans-serif !important;
}

/* datatable checkbox */
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #201f1d;
  border-color: #201f1d;
}

.ant-table-wrapper
  .ant-table-tbody
  .ant-table-row.ant-table-row-selected
  > .ant-table-cell {
  background: none !important;
}
.ant-table-wrapper table tr th.ant-table-selection-column,
:where(.css-dev-only-do-not-override-1rqnfsa).ant-table-wrapper
  table
  tr
  td.ant-table-selection-column,
:where(.css-dev-only-do-not-override-1rqnfsa).ant-table-wrapper
  .ant-table-selection-column {
  padding-inline-end: 20px;
  padding-inline-start: 19px;
  text-align: center;
}

:where(.css-dev-only-do-not-override-1rqnfsa).ant-checkbox-wrapper:not(
    .ant-checkbox-wrapper-disabled
  ):hover
  .ant-checkbox-inner,
:where(.css-dev-only-do-not-override-1rqnfsa).ant-checkbox:not(
    .ant-checkbox-disabled
  ):hover
  .ant-checkbox-inner {
  border-color: #e3e6ea;
}
.p-datatable-table .p-datatable-thead tr th {
  background-color: #f2f7f6;
  color: #111;
  font-weight: 500;
  padding: 15px;
  font-size: 16px;
  font-family: "Fira Sans", sans-serif;
}
.dashboard-table .table tbody tr td {
  border-bottom: 1px solid #dbdbdb;
  font-size: 13px;
  padding: 10px 15px;
}
.dashboard-table .table td .table-avatar a {
  font-weight: 600;
}
.p-datatable .p-paginator:before {
  content: "Show";
  left: 15px;
  position: absolute;
  font-size: 14px;
  color: #111;
}
/* div.p-dropdown.p-component.p-inputwrapper.p-inputwrapper-filled {
  padding: 5px;
  width: auto;
  height: auto;
  border: 2px solid #dbdbdb;
  color: #737373;
  font-size: 16px;
  margin-left: 15px;
  align-items: center;
} */
.p-datatable .p-dropdown {
  left: 40px;
  position: absolute;
}
.p-datatable .p-paginator:after {
  content: "Entries";
  left: 10%;
  position: absolute;
  font-size: 14px;
  color: #111;
}
.p-paginator-bottom.p-paginator.p-component {
  margin: 10px;
}
.table-responsive.dashboard-table .p-datatable button svg {
  height: 60px !important;
  color: #000 !important;
  width: 30px !important;
}
.p-paginator-page.p-paginator-element {
  border-radius: 10px;
  background-color: #127384;
  color: #ffffff;
  border-color: #127384;
}
/* .p-paginator-prev::before {
  content: "Prev";
  color: #2f2f2f;
} */
/* .p-paginator-next::after {
  content: "Next";
  color: #2f2f2f;
} */
/* .p-paginator-prev.p-paginator-element {
  position: absolute;
  right: 15%;
} */
/* span.p-paginator-pages {
  position: absolute;
  right: 10%;
} */
/* .p-paginator-next.p-paginator-element {
  position: absolute;
  right: 5%;
} */
.p-dropdown-trigger svg {
  width: 10px;
}
.p-dropdown span.p-dropdown-label.p-inputtext {
  font-size: 16px;
  font-family: "Fira Sans", sans-serif;
  
}
.p-dropdown-trigger {
  width: 20px;
  justify-content: flex-end;
  left: 10px;
  position: relative;
}
#tablefilter label::before {
  position: absolute;
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
  font-size: 15px;
  content: "\f002";
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
  color: #737373;
}
#tablefilter label {
  position: relative;
}
#tablefilter input:not(:placeholder-shown) + label::before {
  right: 19%;
  top: 13%;
}
.p-paginator-page.p-paginator-element.p-link.p-paginator-page-end {
  margin-left: 5px;
}
.dashboard-table .table td .table-avatar a {
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 6px;
  color: #111;
}
button.p-paginator-page.p-paginator-element.p-link {
  line-height: normal !important;
  height: auto;
}
div.p-paginator {
  justify-content: flex-end;
}
button.p-paginator-element:hover {
  background: none;
}
.p-paginator-page.p-paginator-element:hover {
  background-color: #127384;
}
.popular-services .slick-next {
  right: -6px;
}
.rental-deal-slider .slick-slider button.slick-prev,
.popular-cartype-slider .slick-slider button.slick-prev {
  height: 55px;
  width: 55px;
  background: #ffffff;
  margin: auto 0;
  border-radius: 50%;
  z-index: 1;
}
.rental-deal-slider .slick-slider button.slick-next,
.popular-cartype-slider .slick-slider button.slick-next {
  height: 55px;
  width: 55px;
  background: #ffffff;
  margin: auto 0;
  border-radius: 50%;
  z-index: 1;
}

.rental-deal-slider .slick-slider button.slick-arrow.slick-prev:before,
.popular-cartype-slider .slick-slider button.slick-arrow.slick-prev:before {
  font-family: "FontAwesome";
  content: "\f060";
  color: #28283c;
}
.rental-deal-slider .slick-slider button.slick-arrow.slick-next:before,
.popular-cartype-slider .slick-slider button.slick-arrow.slick-next:before {
  font-family: "FontAwesome";
  content: "\f061";
  color: #28283c;
}
/* .img-slider .slick-slider button.slick-prev{
  height: 16px;
  width: 16px;
  background: rgba(0, 0, 0, 0.5019607843) !important;
  color: #ffffff;
  font-size: 10px;
  position: absolute;
  top: 50%;
}
.img-slider .slick-slider button.slick-next {
  height: 16px;
  width: 16px;
  background: rgba(0, 0, 0, 0.5019607843) !important;
  color: #ffffff;
  font-size: 10px;
  position: absolute;
  top: 50%;
}
    width: 16px;
    background: rgba(0, 0, 0, 0.5019607843) !important;
    color: #ffffff;
    font-size: 10px;
    position: absolute;
    top: 50%;
} */

.testimonial-thumbnails .slick-slide {
  width: 45px !important;
  height: 45px;
  border-radius: 50%;
}
.popular-explore .img-slider button.slick-prev {
  display: block;
  left: 21px;
  background: transparent !important;
  z-index: 99;
}
.popular-explore .img-slider button.slick-next {
  background: transparent !important;
  display: block;
  right: 15px;
}
.popular-explore .img-slider .slick-dots,
.yacht-image-slider .slick-dots,
.rated-yacht-img .slick-dots,
.image-slider .slick-dots {
  bottom: 10px;
 
}
.yacht-image-slider button.slick-next,
.rated-yacht-img .image-slider button.slick-next {
  background: transparent !important;
  display: block;
  right: 15px;
  opacity: 0;
}
.yacht-image-slider button.slick-next {
  background: transparent !important;
  display: block;
  right: 15px;
  opacity: 0;
}
.listing-img .img-slider button {
  opacity: 0;
}
.listing-img:hover .img-slider button {
  opacity: 1;
}

.yacht-image-slider button.slick-prev,
.rated-yacht-img .image-slider button.slick-prev {
  display: block;
  left: 21px;
  background: transparent !important;
  z-index: 99;
  opacity: 0;
}

.yacht-image-slider button.slick-prev {
  display: block;
  left: 21px;
  background: transparent !important;
  z-index: 99;
  opacity: 0;
}
.rated-yacht-img:hover .image-slider button,
.yacht-image-slider:hover button {
  opacity: 1;
}
.brand-slider .slick-slider .brand-item img {
  width: auto;
}
.bg-primary {
  background: #dd000b !important;
}
.banner-img-slider .slick-slider button.slick-prev {
  right: 61px;
  z-index: 2;
  top: 54%;
  left: unset;
  top: 47%;
  left: unset;
}
.banner-img-slider .slick-slider button.slick-next {
  right: 59px;
  z-index: 1;
  top: 52%;
}
.banner-img-slider .slick-slider button.slick-arrow.slick-next:before {
  font-family: "FontAwesome";
  content: "\f061";
}
.banner-img-slider .slick-slider button.slick-arrow.slick-prev:before {
  font-family: "FontAwesome";
  content: "\f060";
}
.banner-yacht-type-slider .slick-slider button {
  display: none !important;
}

.popular-location-sec .popular-location-slider .slick-slider button {
  width: 33px;
  height: 33px;
  border-radius: 50%;
  background: #0a152f;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s all;
  border: 1px solid #0a152f;
  margin-top: 16px;
}
.blog-section .blog-slider .slick-slider button {
  width: 33px;
  height: 33px;
  border-radius: 50%;
  background: #ffffff;
  color: #0a152f;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s all;

  margin-top: 16px;
}
.popular-location-slider .slick-slider button.slick-arrow.slick-next:before {
  font-family: "FontAwesome";
  content: "\f054";
  color: #ffffff;
}
.blog-section .blog-slider .slick-slider button.slick-arrow.slick-next:before,
.category-section
  .bike-category-slider
  .slick-slider
  button.slick-arrow.slick-next:before,
.bike-feature-slider .slick-slider button.slick-next:before {
  font-family: "FontAwesome";
  content: "\f054";
  color: #000;
}
.blog-section .blog-slider .slick-slider button.slick-arrow.slick-prev:before,
.category-section
  .bike-category-slider
  .slick-slider
  button.slick-arrow.slick-prev:before,
.bike-feature-slider .slick-slider button.slick-prev:before {
  font-family: "FontAwesome";
  content: "\f053";
  color: #000;
}
.popular-location-slider .slick-slider button.slick-arrow.slick-prev:before {
  font-family: "FontAwesome";
  content: "\f053";
  color: #ffffff;
}
.bike-feature-slider .slick-slider button.slick-prev {
  left: -15px;
  width: 33px;
  height: 33px;
  border-radius: 50px;
  background: #ffffff;
  z-index: 1;
}
.bike-feature-slider .slick-slider button.slick-next {
  width: 33px;
  height: 33px;
  border-radius: 50px;
  background: #ffffff;
  z-index: 1;
  right: -15px;
}
.recommend-slider .slick-slider .slick-active {
  opacity: 0.2;
  transition: 0.6s ease;
}
.recommend-slider .slick-slider .slick-active .slick-center {
  opacity: 1;
}

.popular-explore .img-slider .slick-dots {
  bottom: 10px;
  color: #fff;
}
.bg-custom.p-calendar.p-component.p-inputwrapper .p-inputtext.p-component {
  background: #f5f5f5;
  border: 1px solid #f4f4f4;
}
span.anticon.anticon-close-circle svg {
  display: none;
}
.group-img.style-custom .ant-picker .ant-picker-input input {
  padding: 0px 15px;
}
.p-dropdown.p-component.p-inputwrapper {
  color: #878a99;
  line-height: 39px;
  padding-right: 30px;
  padding-left: 15px;
  background: transparent;
  background: #f5f5f5;
  border: 1px solid #f4f4f4;
  border-radius: 5px;
}
.p-dropdown:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: none;
  border-color: #f4f4f4;
}
.p-dropdown:not(.p-disabled):hover {
  border-color: #f4f4f4;
}
span.p-dropdown-item-label {
  font-size: 14px;
  font-family: "Fira Sans", sans-serif;
}
li.p-dropdown-item {
  padding: 8px;
}
li.p-dropdown-item.p-highlight {
  background: #127384;
  color: #ffffff;
  padding: 8px;
}
.popular-location-slider .slick-slider button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
}
.popular-location-slider .slick-slider .slick-prev {
  left: 50%;
  z-index: 1;
  bottom: -60px;
  top: unset;
}
.popular-location-slider .slick-slider .slick-next {
  left: 54%;
  z-index: 1;
  bottom: -60px;
  top: unset;
  right: unset;
}
.listing-item .listing-img .img-slider {
  width: 100%;
}
.customdropdown .p-dropdown.p-component.p-inputwrapper {
  background: none;
  border: none;
  font-size: 18px;
  color: #111;
  padding-left: 0px;
}

input.p-inputtext {
  font-family: "Fira Sans", sans-serif;
}
.ant-picker div.ant-picker-input input {
  font-family: "Fira Sans", sans-serif;
}
.footer .footer-contact .footer-contact-info .update-form .btn .icon-send {
  color: #ffffff;
}
.category-section .bike-category-slider .slick-slider button.slick-prev,
.category-section .bike-category-slider .slick-slider button.slick-next {
  width: 33px;
  height: 33px;
  color: #000;
  background: #ffffff;
  box-shadow: 0px 4px 24px 0px rgba(225, 225, 225, 0.2509803922);
  border-radius: 50px;
  border: 1px solid #f4f4f4;
  z-index: 1;
}
.category-section .bike-category-slider .slick-slider button.slick-next {
  right: -17px;
}
.category-section .bike-category-slider .slick-slider button.slick-prev {
  left: -15px;
}

.datetimepicker.p-calendar {
  display: flex;
  width: 100%;
}
.datetimepicker.p-calendar.p-component.p-inputwrapper .p-inputtext.p-component {
  max-width: 100%;
}
.rc-slider .rc-slider-handle {
  border: solid 2px #dd000b;
  background-color: #dd000b;
  opacity: 1;
}

.rc-slider .rc-slider-track,
.rc-slider .rc-slider-tracks {
  position: absolute;
  height: 4px;
  background-color: #dd000b;
  border-radius: 6px;
}

.rc-slider .rc-slider-handle:active {
  border-color: #dd000b;
  box-shadow: none;
}

.rc-slider.rc-slider-horizontal {
  height: 60px;
}
.rc-slider .rc-slider-handle:hover {
  border-color: #dd000b;
}
.rc-slider
  .rc-slider-handle-dragging.rc-slider-handle-dragging.rc-slider-handle-dragging {
  border-color: #dd000b;
  box-shadow: 0 0 0 5px #dd000b;
}
div.filter-range {
  margin-bottom: 0;
}
.about-testimonials .slick-slider button {
  /* display: none !important; */
}
.customcalendar
  .p-calendar.p-component.p-inputwrapper
  .p-inputtext.p-component {
  background: none;
  border: none;
}
ul.slick-dots li button:before {
  display: none;
}
.img-slider .slick-dots li button,
.rated-yacht-img .slick-dots li button,
.image-slider .slick-dots li button {
  width: 4px;
  height: 4px;
  border-radius: 40px;
  background: #dbdbdb;
  margin: 0 0 15px 0;
  margin-right: 5px;
  padding: 3px;
}
.img-slider .slick-dots li.slick-active button,
.rated-yacht-img .slick-dots li.slick-active button,
.image-slider .slick-dots li.slick-active button{
  width: 20px;
  height: 4px;
  background: #dd000b;
}
.slick-slider button {
  z-index: 1;
}
.listing-page-slider .slick-next {
  right: 16px;
}
.listing-page-slider .slick-prev {
  left: 16px;
}
.listing-page-slider.img-slider button.slick-arrow {
  opacity: 0;
  display: block;
  height: 16px;
  width: 16px;
  background: rgba(0, 0, 0, 0.5019607843) !important;
  font-size: 10px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -webkit-transition: 500ms all ease;
  -ms-transition: 500ms all ease;
  transition: 500ms all ease;
  border-radius: 50%;
}
.listing-page-slider:hover button.slick-arrow {
  opacity: 1;
}
.listing-page-slider button.slick-next:before {
  content: "\f105";
  font-family: FontAwesome;
  color: #fff;
}
.listing-page-slider button.slick-prev:before {
  content: "\f104";
  font-family: FontAwesome;
  color: #fff;
}
.listing-page-slider .slick-prev:before,
.listing-page-slider .slick-next:before {
  font-size: 12px;
  color: #fff;
}
.listing-page-slider .slick-arrow.slick-prev {
  z-index: 99;
}
.img-slider.listing-page-slider ul.slick-dots li,
.img-slider ul.slick-dots li,
.image-slider.listing-page-slider ul.slick-dots li,
.yacht-image-slider ul.slick-dots li
  {
  width: auto;
}
.img-slider.listing-page-slider ul.slick-dots li,
.img-slider ul.slick-dots li,
.image-slider.listing-page-slider ul.slick-dots li,
.yacht-image-slider ul.slick-dots li
{
  margin: 0;
}
.img-slider.listing-page-slider ul.slick-dots {
  position: absolute;
  bottom: 0;
}
.listing-page .p-dropdown.p-component.p-inputwrapper {
  border: 1px solid #dbdbdb;
}
.popular-cartype-slider .slick-slider button.slick-prev:hover,
.popular-cartype-slider .slick-slider button.slick-next:hover,
.rental-deal-slider .slick-slider button.slick-next:hover,
.rental-deal-slider .slick-slider button.slick-prev:hover {
  background-color: #dd000b;
}
.popular-cartype-slider .slick-slider button.slick-arrow:hover:before,
.rental-deal-slider .slick-slider button.slick-arrow:hover:before,
.bike-feature-slider .slick-slider button.slick-arrow:hover:before,
.blog-slider .slick-slider button.slick-arrow:hover:before {
  color: #ffffff;
}
.popular-location-slider .slick-slider button.slick-prev:hover,
.popular-location-slider .slick-slider button.slick-next:hover {
  background-color: #ffffff;
}
.popular-location-slider .slick-slider button.slick-arrow:hover:before {
  color: #0a152f;
}
.bike-category-slider .slick-slider button.slick-next:hover,
.bike-category-slider .slick-slider button.slick-prev:hover,
.bike-feature-slider .slick-slider button.slick-prev:hover,
.bike-feature-slider .slick-slider button.slick-next:hover,
.blog-slider .slick-slider button.slick-next:hover,
.blog-slider .slick-slider button.slick-prev:hover {
  background: #127384;
}
.listing-img .img-slider button.slick-prev {
  display: block;
  left: 21px;
  background: transparent !important;
  z-index: 99;
}
.listing-img .img-slider button.slick-next {
  background: transparent !important;
  display: block;
  right: 15px;
}
.listing-item .listing-img .image-slider button.slick-prev,
.listing-item .listing-img .ima-slider button.slick-prev {
  background: transparent !important;
  display: block;
  left: 3px;
  width: 18px;
  height: 18px;
  background-color: rgba(0, 0, 0, 0.5019607843) !important;
  /* opacity: 0; */
}
.listing-img .image-slider:hover button{
  opacity: 1;
}
.listing-item .listing-img .image-slider button.slick-prev::before,
.listing-item .listing-img .image-slider button.slick-next::before,
.listing-item .listing-img .ima-slider button.slick-next::before,
.listing-item .listing-img .ima-slider button.slick-prev::before {
  color: #fff;
  font-size: 10px;
}

.recommend-slider .slick-slider .slick-center {
  opacity: 1;
}
.recommend-slider .slick-slider .slick-active .listing-content {
  display: none;
}
.recommend-slider .slick-slider .slick-center .listing-content {
  display: block;
}
.custom-map-feather {
  position: relative;
}
.custom-map-feather i {
  position: absolute;
  top: -20px;
  right: 0;
}
.info-window {
  /* max-width: 295px;
  max-height: 163px;
  min-width: 0px; */
}
.rental-deal-slider .slick-slider button.slick-next{
  display: flex;
    -webkit-display: flex;
    position: absolute;
    /* top: -50px; */
    right: -3%;
}
.rental-deal-slider .slick-slider button.slick-prev{
  display: flex;
    -webkit-display: flex;
    position: absolute;
    /* top: -50px; */
    left: -3%;
}
.gallery-list .gallery-widget a img{
  border-radius: 10px;
}
.detail-bigimg button.slick-prev:before{
  color:#000;
}
.detail-bigimg button.slick-next:before{
  color:#000;
}
.banner-yacht-type-slider .slider-card h6{
  text-align: center;
}
.customdropdown .p-dropdown span.p-dropdown-label.p-inputtext{
  color:#111
}
.btn-view-custom{
  font-weight: 500;
    font-size: 16px;
    color: #201F1D;
    border: 2px solid #201F1D;
    background-color: #ffffff;
    box-shadow: inset 0 0 0 0 #ffffff;
    border-radius: 5px;
    line-height: 1;
    margin: 0;
    padding: 10px 17px;
}
.btn-view-custom:hover{
  background-color: #dd000b;
    border: 2px solid #dd000b;
    color: #ffffff;
    box-shadow: inset 0 50px 0 0 #dd000b;
}



.booking_custom_check .booking_checkmark .checked-title {
  flex-direction: row !important;
}


.booking_custom_check .booking_checkmark .checked-title:before {
  margin-bottom: 0 !important;
  margin-right: 10px !important;
}

.booking_custom_check .booking_checkmark .checked-title:before {
  width: 20px;
  height: 20px;
}

.booking_custom_check input:checked ~ .booking_checkmark .checked-title::before {
  background: #dd000b;
  border-color: #dd000b;
  transition: 0.5s all;
}

.booking_custom_check input:checked ~ .booking_checkmark .checked-title::after {
  opacity: 0 !important;
}



@media (max-width: 767px) {
  .slick-prev {
    left: -10px !important;
  }

  .slick-next {
    right: -10px !important;
  }
}

.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 15px;
  height: 15px;
  padding: 5px;
  cursor: pointer;
  color: white !important;
  border-radius: 50% !important;
  background: rgb(223, 223, 223) !important
}

.slick-dots li.slick-active button {
  background: #dd000b !important;
}

.modal-dialog {
  max-width: 90% !important;
  margin: auto !important;
}

.car-info-wrapper {
  border-radius: 10px;
  border: 3px solid transparent;
}

.car-info-wrapper-active {
  border-radius: 10px;
  border: 3px solid #dd000b;
}

.car-info-wrapper-disabled {
  opacity: 0.5;
  border-image: initial;
  border-radius: 10px;
}

.car-holder img {
  border-radius: 10px;
}

.banner-slider p {
  color: white;
}

@media (max-width: 767px) {
  .banner-slider p {
    /* color: inherit; */
  }
}

.p-datepicker td > span {
  background: #dd000b;
  color: white;
}


.p-disabled, .p-component:disabled {
  background: transparent !important;
  color: #737373 !important;
}


.p-datepicker table td > span.p-highlight {
  color: #4338ca !important;
  background: #eef2ff !important;
}

.p-datepicker-calendar {
  margin-left: 10px;
  margin-bottom: 10px;
}

.active-prefix .dropdown-item {
  background: #dd000b;
  color: white !important;
}


.css-13cymwt-control {
  border: none !important;
  background: whitesmoke !important;
  height: 41px !important;
  color: #55595b;
}

.PhoneInput {
  height: 41px !important;
  width: 100% !important;
}

.PhoneInputInput {
  width: 100%;
  height: 41px !important;
  border: none !important;
  background: whitesmoke;
  color: #55595b;
  padding: 9px 15px;
  border-radius: 5px !important;
}

.voucher-discount {
  color: green !important;
  font-weight: bold !important;
}


.p-icon {
  display: none !important;
}


button.p-datepicker-next::after {
  content: ">";
}

button.p-datepicker-prev::before {
  content: "<";
}

.service-slider .slick-track {
  display: flex;
  width: 100% !important;
}

.logo-txt {
  letter-spacing: -0.02em;
  font-weight: 700;
  color: #111;
  font-size: 42px;
  font-family: "Fira Sans", sans-serif;
}


.close-btn-custom {
  font-size: 20px;
  color: #111;
}


.join-us-btn, .join-us-btn:active, .join-us-btn:focus, .join-us-btn.show {
  background-color: #dd000a !important;
  padding: 10px 16px;
  border-radius: 6px;
  color: white !important;
  border: none !important;
}

.join-us-btn:hover {
  background-color: #f0f0f0 !important;
  color: #dd000a !important;
}

.dropdown-item-custom {
  padding: 12px 10px;
  font-weight: 500;
  font-size: 15px;
  color: #111;
  margin-left: 8px;
  width: auto !important;
}

.dropdown-wrapper-mobile-custom {
  width: auto !important;
}


.float-whapp {
	position:fixed;
	width:60px;
	height:60px;
	bottom:10px;
	right:10px;
	background-color:#25d366;
	color:#FFF;
	border-radius:50px;
	text-align:center;
  font-size:30px;
	box-shadow: 2px 2px 3px #999;
  z-index:100;
}

.my-float{
	margin-top:16px;
}

.accordion-button:not(.collapsed) {
  color: #111111 !important;
  background-color: #eef4f5 !important;
  box-shadow: inset 0 calc(-1* var(--bs-accordion-border-width)) 0 #137384 !important;
}

.accordion {
  --bs-accordion-border-color: #187485;
}

.tour-home-span-title {
  font-size: 0.95em;
}

.label-custom-search-home {
  font-size: 1.4em !important;
}

.home-color-custom-bubble {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.video-container {
  position: relative;
  width: 100%;
  height: 77vh; /* or any height you prefer */
  overflow: hidden;
}
    
.video-container iframe {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100vw; /* Ensures full viewport width */
  height: 56.25vw; /* Adjust for 16:9 aspect ratio of YouTube videos */
  transform: translate(-50%, -50%); /* Centers the video */
  pointer-events: none; /* Optional, prevents interaction with the video */
}

.video-container .overlay-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white !important;
  text-align: center;
  z-index: 1; /* Ensure text is on top of the video */
}

.video-container .dark-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2); /* Semi-transparent dark overlay */
  z-index: 1; /* Ensures overlay is above the video but below the text */
}


@media (max-width: 767px) {

  .video-container {
    position: relative;
    width: 100%;
    height: 70vh; /* or any height you prefer */
    overflow: hidden;
  }
  .video-container iframe {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100vw; /* Full viewport width */
    height: 100vh; /* Full viewport height */
    object-fit: cover; /* Ensures the video fills the container */
    min-width: 177.78vh; /* Width for 16:9 aspect ratio to cover full height */
    min-height: 100vh; /* Ensures height fills full viewport */
    pointer-events: none; /* Optional, prevents interaction with the video */
  }
}