.benefit-cards {
  background-color: white;
  border-radius: 10px;
  box-shadow:
    0 4px 8px rgba(0, 0, 0, 0.1),
    0 1px 3px rgba(0, 0, 0, 0.06);
}

.banner-text {
  background-color: #dd000b;
}

.banner-text p {
  font-size: 20px;
  color: white;
}

h2 {
  font-size: 24px !important;
  font-weight: 700 !important;
}

.landing-banner-slider {
  position: relative;
  background-image: linear-gradient(rgba(0, 0, 0, 0.15), rgba(0, 0, 0, 0.15)),
    url(../../../public/assets/img/landing-banner.webp);
  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;
  padding: 128px 0 182px;
}

.landing-button {
  width: 100%;
  font-size: 40px;
  border-radius: 25px;
}

@media screen and (max-width: 768px) {
  .landing-banner-slider {
    background-position: right;
  }

  .home-banner h1 {
    font-size: 27px;
  }

  .banner-text p {
    font-size: 15px;
  }

  .landing-button {
    width: 100%;
    font-size: 20px;
    border-radius: 25px;
  }

}

@media screen and (max-width: 576px) {
  .landing-banner-slider {
    background-position: right;
  }
}



.home-banner h1 {
  color: white;

  text-transform: uppercase;
}
