/*-----------------
    8. Breadcrumb
-----------------------*/

.breadcrumb-bar {
    background: $__gray__dark;
    padding: 60px 0 60px;
    position: relative;
    z-index: 1;
    width: 100%;
    @include respond-below(custom991) {
        padding: 50px 0 50px;
     } 
    &:before {
		content: "";
        background: url(../../../../public/assets/img/breadcrumbleft.png) no-repeat;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 393px;
        height: 334px;
        background-size: cover;
        @include respond-below(custom991) {
            display: none;
        } 
	}
    &:after {
		content: "";
        background: url(../../../../public/assets/img/breadcrumbright.png) no-repeat;
        position: absolute;
        bottom: 50px;
        right: 130px;
        width: 97px;
        height: 102px;
        background-size: cover;
        @include respond-below(custom991) {
           display: none;
        } 
	}
    .breadcrumb-title {
        font-weight: 700;
        color: $__white;
        font-size: $__font__size__36;
        @include margin-padding(0 0 15px, null);
        @include respond-below(custom991) {
            font-size: $__font__size__32;
        } 
    }
    .breadcrumb {
        justify-content: center;
        margin: 0;
        li {
            a {
                color: $__white;
            }
        }
        &.active {
            color: $__white;
        }
      }
    .breadcrumb-item+.breadcrumb-item {
        &::before {
            color: $__white;
        }
    }
    .breadcrumb-item.active {
        color: $__primarycolor;
    }
      
      
}