/*-----------------
    13. Common
-----------------------*/

.container, .container-sm {
    @include respond-below(custom1199) {
        max-width: 100%;
    }   
}
.page-wrapper {
    margin-left: 247px;
    padding-top: 77px;
    position: $__relative;
    @include transition(all 0.4s ease);
    @include respond-below(custom1199) {
        margin-left: 200px;
    }
    .content {
        @include margin-padding(null, 25px);
        @include respond-below(custom1199) {
            @include margin-padding(null, 20px);
        }
        @include respond-below(custom991) {
            @include margin-padding(null, 15px);
        }
    }
    @include respond-below(custom991) {
        margin: 0;
        padding-top: 60px;
    }
}
.content-page-header {
    align-items: $__center;
    -webkit-align-items: $__center;
    @include margin-padding(0 0 30px 0, null);
    @extend %display-flex-between;
    @include respond-below(custom575) {
        display: $__block;
    }
    @include respond-below(custom768) {
        @include margin-padding(0 0 30px 0, null);
    }
    h5 {
        font-size: $__font__size__24;
        color: $__secondarycolor;
        font-weight: $__semibold;
        margin: 0;
        @include respond-below(custom768) {
           font-size: $__font__size__18; 
        }
    }
    h6 {
        font-size: $__font__size__20;
        color: $__secondarycolor;
        font-weight: $__semibold;
        margin: 0;
    }  
}
.card {
    background: $__white;
    -webkit-box-shadow: 0 0 13px 0 rgb(82 63 105 / 5%);
    box-shadow: 0 0 13px 0 rgb(82 63 105 / 5%);
    @include margin-padding(0 0 24px 0 , null);
    display: $__inline__block;
    width: $__full__width;
    border: 0;
    @include rounded(8px);
    .card-header {
        border-color: #f0f1f5;
        background-color: $__white;
        padding: 1.5rem;
        &:first-child {
            border-top-left-radius: 20px;
            border-top-right-radius: 20px;
        }
        @include respond-below(custom1199) {
            @include margin-padding(null, 15px);
        }
    }
    .card-body {
        position: $__relative;
        padding: 1.5rem;
        @include respond-below(custom1199) {
            @include margin-padding(null, 15px);
        }
    }
    .card {
        position: $__relative;
        padding: 1.5rem;
        @include respond-below(custom1199) {
            @include margin-padding(null, 15px);
        }
    }
}
.text-success-light {
    color: $__success__light !important;
}
.text-warning-light {    
    color: $__text-warning__light;
}
.warning-border {
    border: 1px solid $__text-warning__light;
    padding: 0 3px;
    border-radius: 50px;
}
.danger-border {
    border: 1px solid $__dangered;
    padding: 0 3px;
    border-radius: 50px;
}
.info-border {
    border: 1px solid $__violet__dark;
    padding: 0 3px;
    border-radius: 50px;
}
.text-primary {    
    color: $__primarycolor !important;
}
.text-danger {
    color: $__dangered !important;
}
.text-gray-light {
    color: $__gray__dark
}
.text-gray-dark {
    color: $__grayish__blue
}
.text-muted {
    color: $__light__vampire !important
}
.bg-light-gray {
    background: $__smoke__white;
}
.bg-primary-light {
    background: $__bg__primary__light
}
.bg-green-light {    
    background: $__bg__green__light
}
.bg-danger-light {
    background: $__bg__danger__light
}
.bg-white-smoke {
    background: $__white__smoke
}
.bg-light-primary {
    background: $__light__vampire !important
}
.dropdown-toggle::after {
    display: $__inline__block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0;
    border-bottom: 0;
    border-left: 0;
}
.dropdown-item {
    font-size: $__font__size__12;
    @include margin-padding(null, 8px 10px);
}
.add-cate-status {
    @include margin-padding(50px 0 0 0 !important, null);
    @include respond-below(custom575) {
        @include margin-padding(0 !important, null); 
    }  
}
.input-block {
    @include margin-padding(0 0 15px 0 , null);
    .forgot-link {
        color: $__dangered;
        font-size: $__font__size__14;
    }
    label {
        @include margin-padding(0 0 10px 0, null);
        font-weight: $__semibold;
        color: $__gray;
        @include respond-below(custom1199){
            font-size:$__font__size__14;
        }
    } 
    .optional {
        font-weight: $__regular;
        font-size: $__font__size__12;
        color: $__gray__dark;
    }
    .form-control {
        background: $__light__hash;
        border: 1px solid $__light__gooses;
        box-shadow: $__none;
        @include rounded(5px);
        @include margin-padding(0, 13px);
        font-size: $__font__size__14;
        font-weight: $__regular;
        color: $__gray__dark;
        @include respond-below(custom767) {
            @include margin-padding(0 0 15px, 10px);
        }
        ::placeholder, ::-webkit-input-placeholder, :-ms-input-placeholder  {
            color: $__gray_light;
            font-size: $__font__size__14;
            font-weight: $__medium;
        }
        &:focus {
            background: #ffffff;
        }
        .form-title {
            font-weight: $__semibold;
            font-size: $__font__size__16;
            color: $__secondarycolor;
            @include margin-padding(0 0 25px 0, null);
        }
    }
    .search-btn {
        .check-available {
            @include margin-padding(null, 12px 20px);
        }
    }
    textarea.form-control {
        height: $__auto;
    }
    .intl-tel-input, .iti {
        width: 100%;
    }
    .note-toolbar {
        background: $__white;
    }
    .note-editable {
        background: $__white;
    }
    .note-resizebar {
        height: 0 !important;
    }
    .toggle-password {
        cursor: pointer;
        position: $__absolute;
        top: 50%;
        right: 0;
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, 50%);
    }
    .feather-eye-off {
        color: $__gray__dark;
    }
    .feather-eye {
        margin-right: 1px;
        color: $__gray__dark;
    }
}
.custom_check {
	position: relative;
    padding-left: 30px;
    .checkmark {
		position: absolute;
		top: 0;
		left: 0;
		height: 16px;
		width: 16px;
		border: 1px solid $__gray__lightdark;
		background-color: $__white;
		border-radius: 2px;
		-moz-transition: all 0.3s ease-in-out;
		-o-transition: all 0.3s ease-in-out;
		-webkit-transition: all 0.3s ease-in-out;
		-ms-transition: all 0.3s ease-in-out;
		transition: all 0.3s ease-in-out;
        &::after {
			content: "\f00c";
			font-family:"Font Awesome 5 Free";
			font-weight: $__bold;
			position: absolute;
			display: none;
			left: 2px;
			top: 2px;
			color: $__gray__lightdark;
			font-size: $__font__size__12;
		}
	}
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        &:checked {
			~ {
				.checkmark {
					background-color: $__white;
                    &:after {
						display: block;
					}
				}
			}
		}
    }    
    span {
        color: $__gray__lightdark;
        font-weight: $__regular;
    }
}
.pass-group {
    position: relative;
}
.close {
    background: $__transparent;
    border: 0;
    color: $__graybackblue;
    font-size: 28px;
    line-height: $__normal;
    top: 20px;
    width: $__auto;
    height: $__auto;
    right: 20px;
    span {
        background: $__dark__gray;
        @include rounded(50px);
        font-size: $__font__size__20;
        width: 30px;
        height: 30px;
        line-height: 30px;
        transition: all 0.4s ease;
        -moz-transition: all 0.4s ease;
        -o-transition: all 0.4s ease;
        -ms-transition: all 0.4s ease;
        -webkit-transition: all 0.4s ease;
        &:hover {
            background: $__primarycolor;
            color: $__white;
            transition: all 0.4s ease;
            -moz-transition: all 0.4s ease;
            -o-transition: all 0.4s ease;
            -ms-transition: all 0.4s ease;
            -webkit-transition: all 0.4s ease;
        }
    }
}
.card-title {
    margin-bottom: 0;
    @include rounded(10px);
}
.cal-icon {
    position: relative;
    width: 100%;
    &:after {
        color: #979797;
        content: "\f073";
        display: $__block;
        font-family: "Font Awesome 5 Free";
        font-weight: $__bold;
        font-size: $__font__size__15;
        margin: $__auto;
        position: $__absolute;
        right: 15px;
        top: 10px;
    }
}
.cal-icon-info {
    &:after {
        font-family: "Feather";
        content: "\e926";
        color: $__gray__dark;
        font-weight: $__regular;
        font-size: $__font__size__18;
        top: 8px;
    }
}
.progress-wrap.active-progress {
	opacity: 1;
	visibility: visible;
	transform: translateY(0);
}
.progress-wrap {
    position: fixed;
    right: 12px;
    bottom: 25px;
    height: 35px;
    width: 35px;
    cursor: pointer;
    display: block;
    border-radius: 50%;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
    box-shadow: inset 0 0 0 2px rgb(75 64 237 / 20%);
    z-index: 99;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 200ms linear;
    transition: all 200ms linear;
    transform: translateY(15px);
    -webkit-transform: translateY(15px);
    -moz-transform: translateY(15px);
    -ms-transform: translateY(15px);
    -o-transform: translateY(15px);
	&::after {
		position: absolute;
		content: '\f062';
        font-family: 'FontAwesome';
		text-align: center;
		line-height: 35px;
		font-size: 17px;
		color: $__white;
		left: 0;
		top: 0;
		height: 35px;
		width: 35px;
		cursor: pointer;
		display: block;
		z-index: 1;
		-webkit-transition: all 200ms linear;
		transition: all 200ms linear;
	}
}
.pagination {
    @extend %display-flex-center;
    @extend %justify-content-center;
    .pagination {
       flex-wrap: wrap;                  
        .previtem,.nextlink {
            @include respond-below(custom479) {
                margin-bottom: 10px;
            }
            a {
                height: 38px;
                @extend %flex-align-center;
                border-radius: 10px;
                padding: 0 20px;
                color: $__gray;
                &:focus {
                    outline: 0;
                    box-shadow: $__none;
                } 
            }
        }
        li {
            .page-group {
                ul {
                    @include respond-below(custom479) {
                        flex-wrap: wrap;
                    }
                    li {
                        @include respond-below(custom479) {
                            margin-bottom: 10px;
                        }
                    }
                }
            }
        }
    }
    .pagination-center {
        display: $__flex;
        justify-content: $__center;
        align-items: $__center; 
        .page-group {
            .page-item {
                a {
                    border: 1px solid $__light__gray;
                    background: $__white;
                    border-radius: 10px;
                    padding: 10px 19px;
                    margin: 0 12px 0 0;
                    color: $__gray;
                    width: 38px;
                    height: 38px;
                    display: $__flex;
                    justify-content: $__center;
                    align-items: $__center;      
                    &:focus {
                        outline: 0;
                        box-shadow: $__none;
                    }    
                    &:hover {
                        background-color: $__dark__blues;
                        color: $__white;
                        border: 1px solid $__dark__blues;
                    }                
                }
                .active {
                    background-color: $__dark__blues;
                    color: $__white;
                    border: 1px solid $__dark__blues;
                }                  
            }
            ul {
                display: $__flex;
                align-items: $__center;
                justify-content: $__center;
                margin: 0 0 0 10px;
            }
        }            
    } 
}

// policy and terms

.privacy-section {
    position: $__relative;
    @include margin-padding(null, 80px 0);
        @include respond-below(custom991) {
            @include margin-padding(null, 40px 0);
        }
    .terms-policy {
        p {
            @include margin-padding(0 0 38px, null);
            @include respond-below(custom991) {
                @include margin-padding(0 0 28px, null);
            }
        }
        ul {
            @include margin-padding(0 0 38px, null);
            li {
                @include margin-padding(0 0 11px, null);
                @extend %display-flex-center;
                span {
                    margin-right: 10px;
                    color: $__text-warning__light;
                }
            }
        }
    }
}
// 404 and 405

.error-page {
    @include margin-padding(null, 50px);
	align-items: center;
    color: $__graybackblue;
    font-weight: $__highbold;
    @extend %display-flex-between;
    @include respond-below(custom575) {
        @include margin-padding(null, 50px);
    }
	.main-wrapper {
		display: flex;
		flex-wrap: wrap;
		height: auto;
		justify-content: center;
		width: 100%;
		min-height: unset;
	}
}
.error-box {
	@include margin-padding(40px auto, null);
	max-width: 600px;
	text-align: $__center;
	width: 100%;
    img {
        @include margin-padding(0 0 50px, null);
    }
	h3 {
        font-size: $__font__size__28;
        margin-bottom: 10px
	}
    p {
        @include margin-padding(auto auto 30px, null);
        color: $__grey;
        font-weight: $__medium;
        max-width: 560px;
    }
	.btn-maintance {
		@include rounded(5px);
		font-weight: $__semibold;
        font-size: $__font__size__16;
        @include margin-padding(null, 12px 20px);
            @include respond-below(custom768) {
                @include margin-padding(null, 8px 12px);
                font-size: $__font__size__14;
            }
	}
    .error-500 {
        @include margin-padding(0 0 60px, null);
    }
    .coming-soon {
        @include margin-padding(null, 50px 0 8px);
        color: $__graybackblue;
        @include respond-below(custom767) {
            font-size: $__font__size__20;
        }
    }
    .back-button {
       .btn  {
            @include margin-padding(null, 15px 20px);
            font-size: $__font__size__16;
                @include respond-below(custom575) {
                    @include margin-padding(null, 10px);
                    font-size: $__font__size__14;
                }
            }
    }
    
}
// Comming soon

.serve-form {
    @include margin-padding(0 0 39px, null);
	@include respond-below(custom767) {
		padding-bottom: 30px;
	}
	.input-block {
		margin-bottom: 0;
		@extend %display-flex-between;
		.form-control {
			margin-right: 10px;
			font-size: $__font__size__14;
            background: $__white;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
			&::placeholder {
				color: $__gray;
				font-weight: $__medium;
			}
			@include respond-below(custom767) {
				min-height: 46px;
				font-size: $__font__size__14;
			}
		}
		.button-notific {
			min-width: 115px;
			height: 47px;
			color: $__white;
			border-radius: 5px;
            background: $__goose-gray;
            font-weight: $__semibold;
            font-size: $__font__size__16;
            @extend %display-flex-center;
            @extend %justify-content-center;
			@include respond-below(custom767) {
				min-width: 100px;
				font-size: $__font__size__14;
			}
			&:hover {
				color: $__white;
                background: $__grayish__blue;
                border: 1px solid $__grayish__blue;
			}
		}
	}
}
.progress-wrap.active-progress {
	opacity: 1;
	visibility: visible;
	transform: translateY(0);
}
.progress-wrap {
	position: fixed;
	right: 12px;
	bottom: 25px;
	height: 35px;
	width: 35px;
	cursor: pointer;
	display: block;
	border-radius: 50%;
	-webkit-border-radius: 50%;
	-moz-border-radius: 50%;
	-ms-border-radius: 50%;
	-o-border-radius: 50%;
	box-shadow: inset 0 0 0 2px rgb(75 64 237 / 20%);
	z-index: 99;
	opacity: 0;
	visibility: hidden;
	-webkit-transition: all 200ms linear;
	transition: all 200ms linear;
	transform: translateY(15px);
	-webkit-transform: translateY(15px);
	-moz-transform: translateY(15px);
	-ms-transform: translateY(15px);
	-o-transform: translateY(15px);
	svg.progress-circle {
		path {
			stroke: $__primarycolor;
			stroke-width: 4;
			box-sizing: border-box;
			-webkit-transition: all 200ms linear;
			transition: all 200ms linear;
		}
	}
	svg {
		path {
			fill: none;
		}
	}
	&::after {
		position: absolute;
		content: '\f062';
		font-family: 'FontAwesome';
		text-align: center;
		line-height: 35px;
		font-size: 17px;
		color: $__primarycolor;
		left: 0;
		top: 0;
		height: 35px;
		width: 35px;
		cursor: pointer;
		display: block;
		z-index: 1;
		-webkit-transition: all 200ms linear;
		transition: all 200ms linear;
	}
}
iframe {
    width: 100%;
}
.custom_radio {
    color: $__black;
    display: inline-flex;
    align-items: center;
    position: relative;
    padding-left: 30px;
    margin-bottom: 10px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}
.custom_radio.active {
    color: #333344;
}
.radio_input .custom_radio + .custom_radio {
    margin-left: 15px;
}
.custom_radio input {
    position: absolute;
    opacity: 0
}
.custom_radio input:checked ~ .checkmark:after {
    opacity: 1;
}
.custom_radio .checkmark {
    position: absolute;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border: 1px solid $__primarycolor;
    border-radius: 50%
}
.custom_radio .checkmark:after {
    display: block;
    content: "";
    position: absolute;
    opacity: 0;
    top: 3px;
    left: 3px;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: $__primarycolor;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out
}
.new-modal {
    .modal-dialog {
        margin-top: 60px;
    }
    .modal-content {
        @include margin-padding(null, 24px);
        @include respond-below(custom767) {
            @include margin-padding(null, 15px); 
        }
        .modal-header {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            justify-content: space-between;
            -webkit-justify-content: space-between;
            -ms-flex-pack: space-between;
            @include margin-padding(0 0 15px, 0 0 15px);
            border-bottom: 1px solid $__light__gooses;
            .modal-title {
                font-size: $__font__size__20; 
                font-weight: $__medium;
                color: $__black-gray;
                margin-bottom: 0; 
                @include respond-below(custom767) {
                    font-size: $__font__size__18; 
                }
            }
            .close-btn {
                position: absolute;
                top: -40px;
                right: 0;
                background-color: $__white;
                border: 1px solid $__white;
                border-radius: 50%;
                color: $__sandstone;
                font-size: $__font__size__22;
                height: 34px;
                width: 34px;
                margin: 0;
                opacity: 1;
                padding: 0;
                z-index: 99;
                display: flex;
                display: -webkit-flex;
                align-items: center;
                -webkit-align-items: center;
                justify-content: center;
                -webkit-justify-content: center;
                -webkit-transform: rotateZ(0deg);
                -moz-transform: rotateZ(0deg);
                transform: rotateZ(0deg);
                @include transition(all .4s ease);
                &:hover {
                    color: $__white;
                    background-color: $__dangered;
                    border: 1px solid $__dangered;
                    -webkit-transform: rotateZ(360deg);
                    -moz-transform: rotateZ(360deg);
                    transform: rotateZ(360deg);
                    @include transition(all .4s ease);
                }
            }
        }
        .modal-body {
            @include margin-padding(null, 0);
        }
    }
}